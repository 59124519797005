// src/pages/BigProjects.tsx
import React, { useState } from 'react';
import Modal from 'react-modal';

const exampleBigProjects = [
  {
    id: 1,
    title: 'Party Game',
    description: 'A party game filled with a variety of different game modes and games! This is essentially my dump for game ideas.',
    details: {
      text: 'Games: shooter, parkour, MMO style boss fight, and more.',
      video: 'https://www.youtube.com/embed/',
      images: [
        'https://via.placeholder.com/600x300.png?text=Screenshot+1',
        'https://via.placeholder.com/600x300.png?text=Screenshot+2',
      ],
      gdd: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      engine: 'Unreal Engine',
      language: 'C++',
    },
  },
  {
    id: 2,
    title: 'RPG Game',
    description: 'A top-down fantasy dungeon crawler style game.',
    details: {
      text: 'Venture deeper into an ancient dungeon, discovering new spells and encountering new enemies.',
      video: 'https://www.youtube.com/embed/',
      images: [
        'https://via.placeholder.com/600x300.png?text=Screenshot+1',
        'https://via.placeholder.com/600x300.png?text=Screenshot+2',
      ],
      gdd: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf',
      engine: 'Unreal Engine',
      language: 'C++',
    },
  },
];

const BigProjects: React.FC = () => {
  const [expandedProject, setExpandedProject] = useState<number | null>(null);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);

  const toggleProject = (id: number) => setExpandedProject(expandedProject === id ? null : id);

  // Function to close modal
  const closeModal = () => setSelectedImage(null);

  return (
    <div className="max-w-screen-lg mx-auto mb-12">
      <h3 className="text-3xl font-semibold mb-6">Big Projects</h3>
      <div className="grid gap-6">
        {exampleBigProjects.map((project) => (
          <div
            key={project.id}
            className="bg-white shadow-lg rounded-lg p-6 transition-transform transform hover:scale-105 cursor-pointer"
            onClick={() => toggleProject(project.id)}
          >
            <h3 className="text-2xl font-semibold">{project.title}</h3>
            <p className="mt-2 text-gray-600">{project.description}</p>
            {expandedProject === project.id && (
              <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-6">
                <div className="col-span-2 space-y-4">
                  {/* Expanded YouTube Video */}
                  <div className="relative pt-[56.25%]">
                    <iframe
                      className="absolute top-0 left-0 w-full h-full rounded-md shadow-md"
                      src={project.details.video}
                      title="YouTube video player"
                      allowFullScreen
                    />
                  </div>

                  {/* Image thumbnails */}
                  <div className="flex overflow-x-auto space-x-4">
                    {project.details.images.map((image, index) => (
                      <img
                        key={index}
                        src={image}
                        alt={`Screenshot ${index + 1}`}
                        className="w-32 h-20 rounded-md shadow-md object-cover cursor-pointer"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevents the parent div's onClick from triggering
                          setSelectedImage(image); // Set the clicked image to open in modal
                        }}
                      />
                    ))}
                  </div>
                </div>
                <div className="col-span-1 bg-gray-100 p-4 rounded-md shadow-md">
                  <h4 className="text-lg font-semibold">Game Details</h4>
                  <p className="mt-2 text-sm text-gray-700">{project.details.text}</p>
                  <div className="mt-4">
                    <p className="text-sm font-semibold">Engine:</p>
                    <p className="text-sm text-gray-700">{project.details.engine}</p>
                    <p className="text-sm font-semibold mt-2">Language:</p>
                    <p className="text-sm text-gray-700">{project.details.language}</p>
                  </div>
                  <a
                    href={project.details.gdd}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="block mt-4 px-4 py-2 bg-blue-500 text-white text-center rounded-md shadow hover:bg-blue-600 transition"
                  >
                    Open Game Design Document (PDF)
                  </a>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Modal for image viewing */}
      {selectedImage && (
        <Modal isOpen={!!selectedImage} onRequestClose={closeModal} className="modal-content" overlayClassName="modal-overlay">
          <div className="relative">
            <button onClick={closeModal} className="absolute top-0 right-0 p-2 text-white bg-black bg-opacity-50 rounded-full">
              ✕
            </button>
            <img src={selectedImage} alt="Expanded view" className="max-w-full max-h-screen rounded-md shadow-md" />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default BigProjects;
// Adjust the class names for the container of BigProjects.tsx
//<div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-6">
