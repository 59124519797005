// src/pages/Hero.tsx
import React, { useState, useEffect } from 'react';
import { FaLinkedin, FaEnvelope } from 'react-icons/fa';
import { FiChevronDown } from 'react-icons/fi';

const Hero: React.FC = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <section className="min-h-screen flex flex-col items-center justify-center text-white text-center bg-gradient-to-br from-pink-400 to-blue-400 animate-gradient-move relative px-4">
      <h1 className="text-4xl sm:text-5xl md:text-6xl font-bold">Tommy Nguyen</h1>
      <p className="mt-4 text-xl sm:text-2xl">Game Designer & Software Engineer</p>
      
      <div className="mt-6 flex space-x-4">
        <a
          href="https://www.linkedin.com/in/tommyn0225"
          target="_blank"
          rel="noopener noreferrer"
          className="text-3xl hover:text-blue-300 transition-colors"
        >
          <FaLinkedin />
        </a>
        <a
          href="mailto:your-email@example.com"
          className="text-3xl hover:text-blue-300 transition-colors"
        >
          <FaEnvelope />
        </a>
      </div>

      {/* Scroll-down arrow */}
      <div
        className={`absolute bottom-10 transition-opacity duration-500 ${
          isScrolled ? 'opacity-0' : 'opacity-100'
        } animate-bounce cursor-pointer`}
        onClick={() => {
          document.querySelector('#about-me')?.scrollIntoView({ behavior: 'smooth' });
        }}
      >
        <FiChevronDown className="text-4xl text-white" />
      </div>
    </section>
  );
};

export default Hero;
