// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './pages/Hero';
import AboutMe from './pages/AboutMe'; // Import AboutMe
import Projects from './pages/Projects';

const App: React.FC = () => {
  return (
    <Router>
      <div>
        <Hero />
        <AboutMe />
        <Projects />
      </div>

      {/* Define your routes */}
      <Routes>
        {/* Other routes for additional pages */}
      </Routes>
    </Router>
  );
};

export default App;
