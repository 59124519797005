// src/pages/SmallProjects.tsx
import React, { useState } from 'react';

const exampleSmallProjects = [
  {
    id: 3,
    title: 'CruzMaps',
    description: 'A fun and quick puzzle game for mobile.',
    details: {
      text: 'A better way to navigate UCSC and get in the know with what is happening on campus. (...)',
      video: 'https://www.youtube.com/embed/', // Example YouTube link
      image: 'https://via.placeholder.com/200x100.png?text=CruzMaps', // Example image
      gdd: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf', // Example PDF download link
    },
  },
  {
    id: 4,
    title: 'SelfCare.io',
    description: 'CalHacks 10.0 submission. Your personalized skin care AI.',
    details: {
      text: 'Developed with Python, Linux, HTML/CSS, (...)',
      video: 'https://www.youtube.com/embed/', // Example YouTube link
      image: 'https://via.placeholder.com/200x100.png?text=SelCare.io', // Example image
      gdd: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf', // Example PDF download link
    },
  },
  {
    id: 5,
    title: 'S-Box',
    description: 'A Spotify RFID jukebox.',
    details: {
      text: 'Wrote Spotify song IDs onto RFID cards. Raspberry Pi reads these cards and automatically plays the song on spotify like a jukebox. (...)',
      video: 'https://www.youtube.com/embed/', // Example YouTube link
      image: 'https://via.placeholder.com/200x100.png?text=S-Box', // Example image
      gdd: 'https://www.w3.org/WAI/ER/tests/xhtml/testfiles/resources/pdf/dummy.pdf', // Example PDF download link
    },
  },
];

const SmallProjects: React.FC = () => {
  const [expandedProject, setExpandedProject] = useState<number | null>(null);

  const toggleProject = (id: number) => {
    setExpandedProject(expandedProject === id ? null : id);
  };

  return (
    <div className="max-w-screen-lg mx-auto mb-12">
      <h3 className="text-3xl font-semibold mb-6">Small Projects</h3>
      <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">
        {exampleSmallProjects.map((project) => (
          <div
            key={project.id}
            className={`bg-white shadow-lg rounded-lg p-4 transition-transform transform hover:scale-105 cursor-pointer ${
              expandedProject === project.id ? 'max-h-screen' : 'max-h-24'
            } overflow-hidden`}
            onClick={() => toggleProject(project.id)}
          >
            <h3 className="text-xl font-semibold">{project.title}</h3>
            <p className="mt-1 text-gray-600">{project.description}</p>
            {expandedProject === project.id && (
              <div className="mt-3 text-gray-500 space-y-3">
                {/* Project Text */}
                <p className="text-gray-700">{project.details.text}</p>

                {/* Project Image */}
                <img
                  src={project.details.image}
                  alt={project.title}
                  className="w-full rounded-md shadow-md"
                />

                {/* YouTube Video */}
                <div className="relative pt-[56.25%] mt-4">
                  <iframe
                    className="absolute top-0 left-0 w-full h-full rounded-md shadow-md"
                    src={project.details.video}
                    title={`${project.title} video`}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                  />
                </div>

                {/* GDD Button */}
                <a
                  href={project.details.gdd}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="block mt-4 px-4 py-2 bg-blue-500 text-white text-center rounded-md shadow hover:bg-blue-600 transition"
                >
                  Open Game Design Document (PDF)
                </a>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default SmallProjects;

// Adjust the container of SmallProjects.tsx
//<div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3">

