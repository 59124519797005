// src/pages/Projects.tsx
import React from 'react';
import BigProjects from './BigProjects';
import SmallProjects from './SmallProjects';

const Projects: React.FC = () => {
  return (
    <section className="min-h-screen bg-gray-100 py-10">
      <h2 className="text-4xl font-bold text-center mb-10">My Projects</h2>
      <BigProjects />
      <SmallProjects />
    </section>
  );
};

export default Projects;
